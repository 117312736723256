// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt';

const over = {
  loginEndpoint: 'https://api.awlers.africa/api/auth',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
};
const { jwt } = useJwt(over);

export default jwt;
